<template>
  <div class="custom-body">
    <div class="custom-search">
      <Form :model="SearchForm" inline @submit.native.prevent>
        <FormItem>
          <Input placeholder="请输入企业名称" v-model="SearchForm.enterprise_name" type="text" search enter-button
                 @on-search="init"></Input>
        </FormItem>
        <FormItem>
          <Button @click="add_show = true">添加</Button>
        </FormItem>
      </Form>
    </div>
    <div class="custom-card" :class="{'card-error': data.length == 0}">
      <Row :gutter="16" align="top" v-show="data.legnth != 0">
        <Col span="6" class-name="custom-col" v-for="(item,index) in data" :key="index">
          <Card class="card">
            <Spin size="large" fix v-if="spin_show"></Spin>
            <h4 slot="title" class="card-title">{{ item.enterprise_name }}</h4>
            <div class="card-right-top-icon">
              <i class="iconfont icon-shezhi1"></i>
            </div>
            <div>
              <p><img :src="'https://'+item.icon" style="width: 70px;height: 70px;"></p>
              <p>外网面板地址：</p>
              <p>{{ item.extranet_ip?item.extranet_ip:'无' }}</p>
              <p>内网面板地址：</p>
              <p>{{ item.intranet_ip?item.intranet_ip:'无' }}</p>
            </div>
            <div style="text-align: right;">
              <span style="color: rgb(102,177,255);cursor: pointer;" @click="see(item.id)">查看详情</span>
            </div>
          </Card>
        </Col>
      </Row>
      <div v-show="data.length == 0">
        暂无数据
      </div>
      <Page v-show="data.length != 0" :total="SearchForm.total" style="text-align: right;" @on-change="set_current"
            @on-page-size-change="set_per" :page-size-opts="[10,15,20]" :page-size="SearchForm.limit"
            :current="SearchForm.page"
            show-total show-sizer></Page>
    </div>
    <Modal v-model="add_show" title="添加客户" ok-text="添加" @on-ok="add_ok" @on-cancel="add_cancel" :loading="add_loading"
           :width="800">
      <Form :model="add_data" label-colon label-position="right" :label-width="130" :rules="add_rules" ref="addForm">
        <FormItem label="姓名" class="modal-Item" prop="name">
          <Input type="text" v-model="add_data.name" placeholder="请输入姓名"></Input>
        </FormItem>
        <FormItem label="联系电话" class="modal-Item" prop="tel">
          <Input type="text" v-model="add_data.tel" placeholder="请输入联系电话"></Input>
        </FormItem>
        <FormItem label="企业名称" class="modal-Item" prop="enterprise_name">
          <Input type="text" v-model="add_data.enterprise_name" placeholder="请输入企业名称"></Input>
        </FormItem>
        <FormItem label="企业头像" class="modal-Item" prop="icon">
          <Upload style="float:left" :action="apiUrl+'/adm/upload_object'" :data="{dir:'customer/temp'}"
                  @upload_succ="upload_succ"></Upload>
          <img class="img" v-if="add_data.icon" :src="'https://'+add_data.icon" alt=""/>
        </FormItem>
        <FormItem label="服务器外网地址" class="modal-Item" prop="extranet_ip">
          <Input type="text" v-model="add_data.extranet_ip" placeholder="请输入服务器外网地址"></Input>
        </FormItem>
        <FormItem label="服务器内网地址" class="modal-Item" prop="intranet_ip">
          <Input type="text" v-model="add_data.intranet_ip" placeholder="请输入服务器内网地址"></Input>
        </FormItem>
        <FormItem label="服务器账号" class="modal-Item" prop="account_number">
          <Input type="text" v-model="add_data.account_number" placeholder="请输入服务器账号"></Input>
        </FormItem>
        <FormItem label="服务器密码" class="modal-Item" prop="password">
          <Input type="password" v-model="add_data.password" placeholder="请输入服务器密码"></Input>
        </FormItem>
        <FormItem label="服务器IP" class="modal-Item" prop="ip">
          <Input type="text" v-model="add_data.ip" placeholder="请输入IP"></Input>
        </FormItem>
      </Form>
    </Modal>
    <information ref="information"></information>
  </div>
</template>

<script>
import Upload from '../../../components/common/Uploads.vue'
import information from './custom_information.vue'

export default {
  components: {
    Upload,
    information
  },
  data() {
    return {
      spin_show: false,
      add_show: false,
      add_loading: true,
      apiUrl: this.ApiUrl,
      SearchForm: {
        enterprise_name: null,
        page: 1,
        limit: 8,
        total: 0
      },
      data: [],
      see_data: {
        merchant_number: null,
        name: null,
        tel: null,
        enterprise_name: null,
        extranet_ip: null,
        intranet_ip: null,
        account_number: null,
        password: null,
        ip: null,
        icon: null
      },
      add_rules: {
        name: [{
          required: true,
          message: '请输入姓名',
          trigger: 'blur'
        }],
		icon: [{
			required: true,
			message: '请上传头像',
			trigger: 'blur'
		}],
        tel: [{
          required: true,
          message: '请输入手机号',
          trigger: 'blur'
        },
          {
            type: 'number',
            message: '请填写正确的手机号',
            trigger: 'blur',
            transform(value) {
              var isMobie = /^1[345789]\d{9}$/
              if (!isMobie.test(value)) {
                return false
              } else {
                return Number(value)
              }
            }
          },
        ],
        enterprise_name: [{
          required: true,
          message: '请输入企业名称',
          trigger: 'blur'
        }],
        extranet_ip: [{
          required: true,
          message: '请输入外网地址',
          trigger: 'blur'
        }],
        intranet_ip: [{
          required: true,
          message: '请输入内网地址',
          trigger: 'blur'
        }],
        account_number: [{
          required: true,
          message: '请输入服务器账号',
          trigger: 'blur'
        }],
        password: [{
          required: true,
          message: '请输入服务器密码',
          trigger: 'blur'
        }],
        ip: [{
          required: true,
          message: '请输入ip地址',
          trigger: 'blur'
        }]
      },
      add_data: {
        name: null,
        tel: null,
        enterprise_name: null,
        extranet_ip: null,
        intranet_ip: null,
        account_number: null,
        password: null,
        ip: null,
        icon: null
      },
    }
  },
  created() {
    this.init()
  },
  methods: {
    //图片上传回调
    upload_succ(res) {
      this.add_data.icon = res.pic_url
      this.$Message.success('上传成功')
    },
    init() {
      var _this = this
      _this.spin_show = true
      var SearchForm = _this.SearchForm

      _this.requestApi('/adm/custom_list', {
        SearchForm
      }).then(
          (res) => {
            //加载数据
            _this.data = res.data.data
            //获取总条数
            _this.SearchForm.total = res.data.total
            //获取当前页
            _this.SearchForm.page = res.data.current_page
            //获取每页条数
            _this.SearchForm.limit = res.data.per_page
            //关闭加载状态
            _this.spin_show = false
            //提示语
            // _this.$Message.success(res.msg)
          }
      )
    },
    //查看更多
    see(id) {
      this.$refs['information'].see_show = true
      this.$refs['information'].id = id
      this.$refs['information'].is_modify = true
      this.$refs['information'].init()
    },
    //切换页数触发
    set_current(page) {
      //赋值当前页数
      this.SearchForm.page = page
      //重载数据
      this.init()
    },
    //切换显示条数触发
    set_per(limit) {
      //赋值切换条数
      this.SearchForm.limit = limit
      //重载数据
      this.init()
    },
    //添加客户
    add_ok() {
      var _this = this
      var add_data = this.add_data
      this.$refs['addForm'].validate(valid => {
        if (valid) {
          _this.requestApi('/adm/custom_add', {add_data}).then(
              (res) => {
                if (res.code == 1) {
                  _this.add_loading = false
                  _this.add_show = false
                  _this.$Message.success('添加成功')
                  _this.add_data = {
                    name: null,
                    tel: null,
                    enterprise_name: null,
                    extranet_ip: null,
                    intranet_ip: null,
                    account_number: null,
                    password: null,
                    ip: null,
                    icon: null
                  }
                  _this.$refs['addForm'].resetFields()
                  _this.init()
                }
              }
          )

        } else {
          setTimeout(() => {
            _this.add_loading = false // 改变加载状态，且让弹窗无法自动关闭
            _this.$nextTick(() => {
              _this.$Message.error('请完善信息')
              _this.add_loading = true
            })
          }, 0)
          return
        }
      })

    },
    //取消添加客户
    add_cancel() {
      this.add_data = {
        name: null,
        tel: null,
        enterprise_name: null,
        extranet_ip: null,
        intranet_ip: null,
        account_number: null,
        password: null,
        ip: null,
        icon: null
      }
      this.$refs['addForm'].resetFields()
    }
  }
}
</script>

<style scoped>
.custom-body {
  background-color: #fff;
  padding: 10px;
}

.custom-search {
  background-color: rgb(248, 248, 249);
  padding: 5px;
  margin-bottom: 10px;
}

.card-title {
  text-align: center;
}

.ivu-form-item {
  margin-bottom: 0px !important;
}

.card {
  width: 100%;
}

.custom-col {
  margin-bottom: 10px;
}

.card-right-top-icon {
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  height: 0;
  border-top: 35px solid rgb(235, 181, 99);
  border-left: 35px solid transparent;
}

.custom-card {
  height: 100%;

}

.iconfont {
  position: absolute;
  top: -35px;
  right: 3px;
  color: #FFF;
  font-size: 13px;
}

.enterprise_name {
  width: 100px;
  height: 100px;
  background-color: red;
}

.card-error {
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal-Item {
  margin-bottom: 25px !important;
}

.img {
  width: 77px;
  height: 77px;
  margin-left: 8px;
}

</style>
