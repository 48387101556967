<template>
  <div>
    <Drawer title="客户详情" v-model="see_show" width="510" @on-close="see_cancel">
      <Spin size="large" fix v-if="spin_show"></Spin>
      <Row>
        <Col span="10" style="text-align: center;">
          <img class="icon" :src="'https://'+data.icon"/>
          <div v-if="is_modify">
            <Upload :action="apiurl+'/adm/upload_object'" :show-upload-list="false" :on-success="upload_callback">
              <Button type="text" class="ci-btn-edit-img">修改
                <icon class="iconfont icon-edit"/>
              </Button>
            </Upload>
          </div>
        </Col>
        <Col span="14" style="padding-left: 16px;padding-right: 16px;">
          <div class="cusotm-details">ID：{{ data.id }}</div>
          <div class="cusotm-details">登录账号：{{ data.name }}</div>
          <div class="cusotm-details">创建时间：{{ data.create_time }}</div>
        </Col>
      </Row>
      <Divider/>
      <Form :model="data" :label-colon="true">
        <Row>
          <Col :span="12">
            <FormItem label="姓名" style="padding-left: 16px;padding-right: 16px;">
              <Input type="text" :disabled="!is_modify" placeholder="请输入姓名" v-model="data.name" class="input"/>
            </FormItem>
          </Col>
          <Col :span="12">
            <FormItem label="手机号" style="padding-left: 16px;padding-right: 16px;">
              <Input type="text" maxlength="11" :disabled="!is_modify" placeholder="请输入手机号" v-model="data.tel"/>
            </FormItem>
          </Col>
        </Row>
        <Divider/>
        <Row>
          <Col :span="12" style="padding-left: 16px;padding-right: 16px;">
            <FormItem label="企业名称">
              <Input type="text" placeholder="请输入企业名称" :disabled="!is_modify" v-model="data.enterprise_name"/>
            </FormItem>
            <FormItem label="外网地址">
              <Input type="text" placeholder="请输入服务器外网地址" :disabled="!is_modify" v-model="data.extranet_ip"/>
            </FormItem>
            <FormItem label="服务器账号">
              <Input type="text" placeholder="请输入服务器账号" :disabled="!is_modify" v-model="data.account_number"/>
            </FormItem>
          </Col>
          <Col :span="12" style="padding-left: 16px;padding-right: 16px;">
            <FormItem label="IP地址">
              <Input type="text" placeholder="请输入IP地址" :disabled="!is_modify" v-model="data.ip"/>
            </FormItem>
            <FormItem label="内网地址">
              <Input type="text" placeholder="请输入服务器内网地址" :disabled="!is_modify" v-model="data.intranet_ip"/>
            </FormItem>
            <FormItem label="服务器密码">
              <Input type="password" placeholder="请输入服务器密码" :disabled="!is_modify" :password="true"
                     v-model="data.password"/>
            </FormItem>
          </Col>
        </Row>
        <Divider/>
        <Row>
          <Col :span="12" style="padding-left: 16px;padding-right: 16px;">
            <div class="row-footer">拥有站点（个）</div>
            <div class="row-footer row-icon">
              <i class="iconfont icon-org"/>
            </div>
            <div class="row-footer">
              {{ data.count }}
            </div>
          </Col>
        </Row>
        <div class="ci-btn-submit" v-if="is_modify">
          <Button type="primary" @click="see_ok">提交</Button>
        </div>
      </Form>
    </Drawer>
  </div>
</template>

<script>
export default {
  data() {
    return {
      see_show: false, //显示抽屉
      spin_show: false, //显示加载状态
      apiurl: this.ApiUrl, //api接口域名
      id: null, //客户ID
      is_modify: false, //修改还是查看
      data: {},
      data2: '',
    }
  },
  methods: {
    //获取客户数据
    init() {
      var _this = this
      _this.data = {}
      _this.data2 = ''

      _this.spin_show = true
      _this.requestApi('/adm/custom_get', {
        id: _this.id
      }).then((res) => {
        if(res.code == 200){
          _this.data = res.data
          _this.data2 = JSON.stringify(res.data)
        }else{
          _this.$Message.warning(res.msg)
        }
        _this.spin_show = false
      })
    },
    //文件上传回调
    upload_callback(src) {
      if(src.pic_url){
        this.data.icon = src.pic_url
      }
    },
    see_ok() {
      var _this = this
      var data = _this.data

      //判断是否有修改内容
      if (JSON.stringify(_this.data) === _this.data2) {
        _this.$Message.warning('您未修改任何内容')
        return false
      }

      //开启加载状态
      _this.spin_show = true

      //发送请求
      _this.requestApi('/adm/custom_edit_save', {
        data,
        id: _this.id
      }).then((res) => {
          if(res.code == 200){
            _this.data2 = JSON.stringify(_this.data)
            _this.$Message.success('修改成功')
            _this.$parent.init()
          }else{
            _this.$Message.warning('修改失败')
          }
          _this.spin_show = false
      })
    },
    see_cancel() {
      var _this = this
      if(_this.is_modify === false){
        _this.see_show = false
        return false
      }
      if (JSON.stringify(_this.data) !== _this.data2) {
        _this.$Modal.confirm({
          title: '放弃修改确认',
          content: '您已经修改了客户信息，是否直接关闭？',
          okText: '确认',
          cancelText: '取消',
          onOk: function () {
            _this.see_show = false
          },
          onCancel: function () {
            _this.see_show = true
          }
        })
        return
      }
    }
  }
}
</script>

<style scoped>
.icon {
  width: 77px;
  height: 77px;
}

.cusotm-details {
  margin-bottom: 10px;
}

.row-footer {
  margin-bottom: 10px;
  text-align: center;
}

.row-icon {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  text-align: center;
  line-height: 80px;
  background-color: rgba(0, 0, 0, 0.1);
  margin: 0px auto;
  margin-bottom: 10px;
}

.ci-btn-submit{
  position: absolute;right: 20px;bottom: 15px;
}

.ci-btn-edit-img{
  font-size:10px;display:flex;align-items: center;
}
</style>
